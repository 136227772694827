<template>
  <div>
    <transmission-mediums-filter @active-change="filterActive = $event" :filter-model="filterModel" />
    <transmission-mediums-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import TransmissionMediumsFilter from '@/views/Nsi/TransmissionMediums/TransmissionMediumsFilter';
import TransmissionMediumsTable from '@/views/Nsi/TransmissionMediums/TransmissionMediumsTable';

export default {
  name: 'TransmissionMediums',
  components: { TransmissionMediumsTable, TransmissionMediumsFilter },
  data() {
    return {
      filterModel: {
        nameFilter: ''
      },
      filterActive: true
    };
  }
};
</script>

<style scoped>

</style>
