import axios from 'axios';

export default {
  getTransmissionMediumsWithPagination(pageNumber, pageSize, nameFilter, sortField, sortDesc) {
    return axios.get('/api/TransmissionMediums', {
      params: {
        pageNumber, pageSize, nameFilter, sortField, sortDesc
      }
    });
  },
  addTransmissionMedium: (transmissionMedium) => axios.post('/api/TransmissionMediums', transmissionMedium),
  updateTransmissionMedium: (transmissionMedium) => axios.put(`/api/TransmissionMediums/${transmissionMedium.id}`, transmissionMedium),
  deleteTransmissionMedium: (id) => axios.delete(`/api/TransmissionMediums/${id}`), 
};
